// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investors-index-jsx": () => import("./../../../src/pages/investors/index.jsx" /* webpackChunkName: "component---src-pages-investors-index-jsx" */),
  "component---src-pages-policy-index-jsx": () => import("./../../../src/pages/policy/index.jsx" /* webpackChunkName: "component---src-pages-policy-index-jsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-pages-portfolio-vic-square-index-jsx": () => import("./../../../src/pages/portfolio/vicSquare/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-vic-square-index-jsx" */),
  "component---src-pages-team-index-jsx": () => import("./../../../src/pages/team/index.jsx" /* webpackChunkName: "component---src-pages-team-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

